@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Syne:wght@400;500;600;700;800&display=swap');
.App {
  font-family: 'Montserrat' !important;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  place-items: center;
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
p
{
  font-family: 'Montserrat' !important;
  font-weight: 200;
  letter-spacing: 2px !important;
}
.App-logo {
  height: 600px;
  pointer-events: none;
  z-index: 5;
  position: relative;
}



.header ul{
  display: flex;
  flex-direction: row;
}
.header
{
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  place-items: center;
  justify-content: space-around;
}
.header li {
  list-style: none;
  padding: 6px 12px;
 color: #000;
}
.header li a
{
  text-decoration: none;
  color: none;
  font-family: 'Montserrat' !important;
  font-weight: 500 !important;
  color: #000;
  transition: all 0.05s ease-in-out;
  text-transform: uppercase;
}
.header li a:hover
{
  font-family: 'Montserrat' !important;
  font-weight: 900 !important;
  color: #000;
  text-decoration: underline 3px;
  text-underline-offset: 3px;
  font-style: oblique;
  color: #f89520;
}
span
{
  font-weight: 900;
}
.Logos
{
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: space-around;
}
.logos-cnt
{
  
  width: 100%;
}
.logo-button
{
  height: 80px
}
button:disabled
{
  filter: saturate(0) opacity(0.5);
}
button
{
  border: none;
  filter: saturate(0) opacity(0.5);
  overflow: none;
  background: none;
  transition: all 0.1s ease-in-out;
}
button:hover
{
  filter: saturate(1) opacity(1);
  transform: scale(1.1);
  cursor: pointer;
}
button:hover:active
{
  filter: saturate(1) opacity(1);
  transform: scale(0.9);
  cursor: pointer;
}
.logo-vid
{
  position: absolute;
  z-index: 0;
  width: 400px;
  height: 350px;
  transform: 
  translateX(-70px)
  translateY(-100px);
  border-radius: 50px;
}
.horizontal-ticker
{
  width: 100% !important;
  margin: 0 auto !important;
} 
.url-container
{
  border: 5px solid #000;
  padding: 10px 16px;
  border-radius: 20px;
  background: white;
  box-shadow: 5px 5px 0px #000;

}
.tag
{
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 20px;
}
.logo-text {
  letter-spacing: -2px;
  font-size: 1.5rem;
  font-weight: 900;
}
.remote-cnt
{
  width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  place-items: center;
  transform: translateX(-20px);

}
.remote-img {
  height: 100px;
}
.remote-cnt span
{
  text-transform: uppercase;
  font-family: "Montserrat" !important;
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 20px;

}
.FiveNineLogo {
  width: 150px;
}
@media only screen and (max-width: 600px)
{
  .App-logo {
    height: 400px;
    pointer-events: none;
    z-index: 5;
    position: relative;
  }
  .logo-vid
{
  position: absolute;
  z-index: 0;
  width: 250px;
  height: 180px;
  transform: 
  translateX(-44px)
  translateY(-160px);
  border-radius: 20px;
}
}